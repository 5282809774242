@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: "Lobster", cursive;
  font-family: "Montserrat", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar a {
  /* color: white; */
  margin: 3px 0px 5px -1px;
}
/* .navbar a:hover{
  color: gold;
} */
.lang_area {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px;
  border-radius: 5px;
}

/* .lang_area{
  position: absolute;
  top: 23px;
} */

.goog-te-banner-frame.skiptranslate {
  display: none !important;
  } 

  iframe#\:1\.container {
    display: none;
}
.goog-logo-link {
  /* display:none !important; */
}

.goog-te-gadget {
  /* color: transparent !important; */
}

.goog-te-gadget .goog-te-combo {
  /* color: #996418 !important; */
}
/* #google_translate_element{width:300px;float:right;text-align:right;display:block} */
.goog-te-banner-frame.skiptranslate { display: none !important;} 
body { top: 0px !important; }
#goog-gt-tt{display: none !important; top: 0px !important; } 
.goog-tooltip skiptranslate{display: none !important; top: 0px !important; } 
.activity-root { display: hide !important;} 
.status-message { display: hide !important;}
.started-activity-container { display: hide !important;}
.logo_are {
  background: #ffffff;
  border-radius: 18px;
  /* padding: 0px; */
  /* box-shadow: -1px -1px 10px 10px; */
  border: transparent;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .dre.dropdown {
    margin: 0px;
}
.dp_btn-basic {
  margin: 0px 0px;
  padding: 8px 38px 8px 38px;
  width: 100%;
}
}

.navbar {
  padding: 0 !important;
}

input.me-2.form-control {
  height: 50px;
  margin-top: 4px;
}

button.search_btn.btn.btn-outline-success {
  height: 48px;
  margin-top: 6px;
  background-color: white;
  color: #996418;
  border: solid 1px #996418;
  font-weight: bold;
}

button.search_btn.btn.btn-outline-success {
  height: 48px;
  margin-top: 6px;
  background-color: #996418;
  color: #ffffff;
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-width: 280px !important;
  --bs-offcanvas-bg: #31b1e369 !important;
  --bs-offcanvas-color: white !important;
}

.collapse_logo {
  width: 40%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.offcanvas-header .btn-close {
  margin-bottom: calc(11.5 * var(--bs-offcanvas-padding-y)) !important;
}

.nav_bottom {
  border-bottom: none;
  height: auto;
}

a.brand_tx.navbar-brand {
  font-family: "Lobster", cursive;
}

img.nav_im {
  width: 4em;
}

.nav_ico_sp {
  margin-right: 10px;
}

.logo_menu_box {
  display: grid;
  justify-content: space-evenly;
  justify-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .nav_bottom {
    border-bottom: solid 1px;
    height: 47px;
  }
  img.nav_im {
    width: 2em;
  }
}
