.map_area {
    border: solid 1px #0a4773;
    margin: 10px 0px;
    padding: 10px;
    /* box-shadow: inset 1px 0px 14px 0px #0a4773; */
}
button.map_btn.btn.btn-primary {
    padding: 17px;
    font-weight: bold;
    background-color: #165788;
    border: transparent;
    float: left;
    margin-bottom: 10px;
}
@media only screen and (max-width: 480px) {
    button.map_btn.btn.btn-primary {
        float: none;
    }
  
    }