.bg_bann {
  position: relative;
  background-image: url("../../public/images/slicing/Help101-Web.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  /* Set the height as needed */
}

/* Optional: Add styling for the content within the .bg_bann section */
.bg_bann .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Add any other styles as needed */
}

img.logo_tp {
  width: 45%;
}

.a_btn {
  display: flex;
  margin-top: -15px;
  justify-content: space-between;
}

.dp_btn {
  float: left;
  /* width: 175px; */
  padding: 10px 35px 10px 35px;
  /* margin-left: 25px; */
  margin-top: -15px;
  height: 45px;
  background-color: #008fda;
  color: black;
  border: solid 1px transparent;
  font-weight: bold;
}

.top_spc.row {
  margin-top: 25px;
}

.srch_inp {
  height: 45px;
  background-color: #b5cbd8;
}

.aplus {
  height: 45px;
  width: 60px;
  margin: 5px;
  background-color: #b5cbd8;
  color: black;
  font-weight: bold;
  border: solid 1px transparent;
}

svg.svg-inline--fa.fa-magnifying-glass.sch_icn {
  color: #0067bb;
}

h5.line img {
  width: 24%;
  margin: 0px 0px 8px 0px;
}

.nav_head {
  color: white;
  font-weight: 400;
  margin-top: 5px;
  font-size: 16px;
  margin-bottom: 25px;
}

.tab {
  padding: 10px 20px;
  margin: 0px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  color: #333;
}

.tab.active {
  background-color: #1f6b95;
  color: white;
  border: 1px solid transparent;
}

button.tab img {
  width: 50px;
  margin: auto;
}

button.tab {
  background: #004873;
  opacity: 0.7;
  color: white;
  font-size: 13px;
  height: 100px;
  width: 100%;
  display: grid;
  justify-content: center;
  border: solid 1px transparent;
}

.tab_style.col-md-2 {
  padding: 0px 2px;
}

.tab_con {
  margin-top: -75px;
  background-color: #c1c1c1;
  padding-bottom: 20px;
}

button.collap.btn.btn-primary {
  display: none;
}

.line {
  font-size: 15px;
  padding: 18px;
  margin: 19px 0px 10px 0px;
  text-align: justify;
  color: #0066a7;
  font-weight: 500;
  display: grid;
  justify-items: center;
}

.line_t {
  border-right: solid 3px #5c8cb1;
}

section.bg_blu {
  background-color: #31b0e3;
  padding: 32px;
  border-radius: 5px;
  box-shadow: 0px 8px 20px 0px;
}

.bg_clr_lblu.container-fluid {
  background-color: #bde4f8;
}

p.para {
  color: #1763ad;
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  margin: 25px 10px;
}

.bx_img img {
  width: 90px;
}

.bx {
  border: solid 1px transparent;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 79px -12px rgba(20, 20, 20, 0.4);
  height: 135px;
  width: 80%;
  margin: 29px 30px 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bx:hover {
  border-color: #9c1420;
  box-shadow: 0px 0px 20px 9px #ec6e79;
  transition: transform 0.8s ease;
  transform: scale(1.05);
}

.bx_hd h5 {
  margin-top: 10px;
  font-size: 20px;
}

.bg_clr_lblu.container-fluid {
  background-color: #bde4f8;
  border-radius: 10px;
  padding: 20px;
}

.bg_dblu.container-fluid {
  background-color: #01192b;
}

.socialicon {
  border-bottom: 1px solid #1d9ce1;
}

img.logo_ft {
  width: 37%;
}

.ft_mb3 {
  border: solid 3px #1a435b;
  border-radius: 23px;
  background: #01192b;
}

.addon2ft {
  border-radius: 31px;
  background-color: #326482;
  border: solid 1px transparent;
  color: white;
  font-weight: bold;
}

input.ft_inp.form-control {
  background-color: transparent;
  border: solid transparent;
  color: white;
  border-radius: 22px;
}

.mb-3.ft_mb3.input-group {
  margin-top: 80px;
}

.ft_inp::placeholder {
  color: white;
}

ul.list_ft {
  color: white;
  text-align: justify;
}

img.aa {
  width: 98%;
}

ul.list_ft h5 {
  font-size: 20px;
}

ul.list_ft li {
  margin: 0px 0px 15px 0px;
  font-size: 19px;
  font-weight: lighter;
}

.bnk.row {
  border-bottom: 1px solid #1d9ce1;
}

.socialicon img {
  width: 3%;
  border-radius: 5px;
  margin: 12px 12px 22px;
  float: right;
}

.bg_clr_lbluu {
  background-color: #31b0e3;
  padding: 32px;
  border-radius: 5px;
  box-shadow: 0px 8px 20px 0px;
}

.ft-hr {
  padding: 0;
}

.ft-hr h4 {
  padding: 34px;
  color: white;
  background: #0e2a47;
}

.top-h h4 {
  color: white;
  text-align: justify;
}

.top-h h5 {
  color: white;
  text-align: justify;
}

input.form-control.hhb {
  background: #dde2e8;
  height: 49px;
  border-color: #165788;
  font-size: 20px;
}

span#basic-addon1 {
  background: #165788;
  color: white;
}

.top-12 {
  text-align: left;
  color: white;
  font-size: 19px;
  font-weight: 600;
}

.dgg {
  background-color: #bde4f8;
  border-radius: 10px;
  padding: 30px;
  margin-top: 100px;
}

.hvbj h1 {
  color: #005fad;
}

.hvbj h3 {
  color: #005fad;
}

.hvbj h5 {
  color: #606060;
}

button.btn.btn-secondary.bng {
  padding: 21px 29px;
  background: #eeeeee;
  color: #6b6b6b;
  font-weight: 700;
  font-size: 20px;
}

.md-4.row {
  border-bottom: 3px solid #606060;
  padding: 27px;
}

button.btn.btn-secondary.bfor {
  margin: 72px 20px 43px;
  padding: 14px 20px;
  color: white;
  background: #1f1f1f;
  font-weight: 700;
}

button.bfor.btn.btn-secondary:hover {
  color: white;
  background: #31b0e3;
  border-color: #31b0e3;
}

button.btn.btn-primary.last-b {
  margin-top: 28px;
  padding: 14px 26px;
  font-weight: 600;
  color: white;
  background: #1f1f1f;
  border: #1f1f1f;
}

button.last-b.btn.btn-primary:hover {
  color: white;
  background: #31b0e3;
}

.top-hd h1 {
  text-align: left;
  border-bottom: 2px solid #1f3849;
  padding: 0px 5px 19px 0px;
  font-size: 37px;
  color: black;
  font-weight: 700;
}

.hd-1 {
  text-align: left;
  color: #595959;
  font-size: 13px;
  border-left: solid 5px #02324E;
  margin: 23px 0px 0px 6px;
  padding: 6px 11px;
}

.hd-1 a {
  color: white;
  font-size: 22px;
  margin: 12px;
}

.hd-1 h6 {
  color: white;
  font-size: 16px;
  display: inline;
  margin: 9px;
}

.hd-1 h4 {
  margin: 4px 11px;
}

.md-5 {
  text-align: justify;
  margin: 8px 31px 8px 31px;
}

button.but.btn.btn-primary {
  margin: 15px 2px;
  padding: 7px 35px 7px 35px;
  background: #165788;
  border-color: #165788;
  font-weight: 600;
}

button.but.btn.btn-primary:hover {
  color: #16578b;
  background: #dde2e8;
  font-weight: 700;
  border: solid 3px #16578b;
}

button.btt.btn.btn-primary {
  padding: 7px 15px 7px 15px;
  background: #e4e2e0;
  border-color: #e4e2e0;
  color: black;

}

.fsf {
  text-align: left;
  margin: 25px 10px 30px 10px;
}

.fsf h5 {
  color: white;
  font-size: 23px;
}

.fsf h5 {
  color: white;
  font-size: 23px;
}

.fsf h3 {
  font-size: 30px;
  margin: 28px 0px 14px 0px;
}

.fsf span {
  margin: 0px 15px 0px 0px;
}

button.ft.btn.btn-primary {
  margin: 2px -36px 40px 41px;
  padding: 5px 30px 5px 30px;
  color: #595959;
  background: #dde2e8;
  border: solid 3px #16578B;
  font-size: 17px;
  font-weight: 700;
}

button.ft.btn.btn-primary:hover {
  color: white;
  background: #165788;
}

.fsf h4 {
  color: white;
  font-size: 22px;
}

.ql_fc {
  text-align: left;
}

.ql_fc ul {
  margin: 12px 0px 18px 40px;
}

.ql_fc li {
  font-size: 23px;
  font-weight: 600;
  color: white;
}

.ql_fc h4 {
  margin: 0px 0px 2px 0px;
  color: white;
}

.qli_fc {
  margin: 22px 0px 60px 0px;
}

.sec {
  border: 1px solid;
  padding: 12px 21px 11px 28px;
  margin: 28px -53px 15px 0px;
  background: #cff1f9;
  border-color: #cff1f9;
  border-radius: 12px;
  width: 100%;
}

.flxs {
  display: flex;
}

button.pra.btn.btn-primary {
  margin: 1px 5px 15px 8px;
  background: #165788;
  border-color: #165788;
}

button.btn.btn-primary.pro {
  margin: 1px 7px 16px 11px;
  background: #165788;
  border-color: #165788;
}

/* button#dropdown-basic-button {
  margin: -11px 99px 0px -100px;
  background: #2c8dda;
  border-color: #2c8dda;
  padding: 12px 13px 12px 15px;
} */
.sec h1 {
  text-align: justify;
  color: #555555;
}

.hvbj {
  text-align: left;
}

.bos h3 {
  border: 1px solid;
  margin: 0;
  background: #165788;
  color: white;
  border-color: #165788;
  text-align: justify;
  padding: 18px;
}

.bos h5 {
  padding: 23px;
  border: 1px solid;
  margin: 0;
  background: #1e6da8;
  color: white;
  border-color: #165788;
  font-weight: 400;
  text-align: justify;
}

.last h1 {
  border-bottom: 1px solid;
  padding: 23px;
}

.last h5 {
  border-bottom: 1px solid;
  padding: 25px;
  color: white;
  border-color: #165788;
}

.last {
  text-align: start;
}

.flxs span {
  color: white;
  background: #165788;
  border-color: #165788;
}

button.btn.bt {
  font-size: 13px;
  font-weight: 700;
}

/* .btn-group>.btn-group:not(:first-child),
.btn-group> :not(.btn-check:first-child)+.btn {
  margin-left: calc(var(--bs-border-width) * -1);
  color: black;
  background: azure;
  border-radius: 5px;
  padding: 15px 50px 15px 50px;
  height: 60px;
  margin: 0px 15px 0px 0px;
  border-color: #0298f3;
} */

.mf-4 img {
  width: 10%;
  margin: 6px 8px 12px;
}

.view-font {
  display: inline;
  padding: 9px;
}

img.map-img {
  width: 100%;
}

.sec_area.col-md-8.col-2 {
  display: contents;
}

.nav_back {
  background-color: #005fad;
}

.bs.row {
  margin-top: 8px;
}

.box {
  padding: 50px 30px 50px 30px;
  background: white;
  box-shadow: 0px 3px 79px -12px rgba(20, 20, 20, 0.4);
  margin: 31px 0px 0px 0px;
  border-radius: 15px;
  transition: transform 0.8s ease;
  cursor: pointer;
}

.box:hover {
  background: #1eaae19e;
  color: white;
  transition: transform 0.8s ease;
  transform: scale(1.02);
}

.inner-box1 h2 {
  font-weight: bold;
  font-size: 17px;
  text-align: start;
}

.inner-box1 h6 {
  font-size: 14px;
  text-align: start;
}

.inner-box1 a {
  float: inline-start;
}

.inner-box2 h6 {
  font-size: 13px;
  text-align: end;
}

.inner-box2 h5 {
  font-size: 14px;
  text-align: end;
  color: #1459a3;
  font-weight: bold;
}

.inp {
  border-color: #165788;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
  text-align: start;
  float: left;
}

label.btn.btn-toogle {
  padding: 13px 49px;
  color: white;
  background: #165788;
}

label.btn.btn-toogle:hover {
  color: white;
  background: #165788;
  border-color: #165788;
}


.dropdown.btn-group {
  float: inline-start;
  margin: 0px 0px 0px -13px;
}

.btn-toggle {
  padding: 1px 0px 18px;
}

.btn-toggle h5 {
  font-size: 28px;
  text-align: start;
  color: #165788;
  margin-top: -14px;
}

.collapsible {
  display: none;
}

.call.collapse.show {
  display: flex;
  margin-top: -25px;
}

.collapse:not(.show) {
  display: flex;
  margin-top: -25px;
}

.ai.accordion-item {
  width: 57%;
  margin-top: -21px;
}

.inner-box2 h2 {
  text-align: right;
  font-size: 30px;
}

.inner-box2 h4 {
  text-align: right;
}

.inner-box2 img {
  width: 6%;
}

.dp_btn-basic:active {
  background-color: #2d5f6c;
  border: transparent;
}

.dp_btn-basic:focus {
  background-color: #2d5f6c;
  border: transparent;
}

.dp_btn-basic:hover {
  background-color: #2d5f6c;
  border: transparent;
}

.dre.dropdown {
  /* margin: 13px 2px 0px 37px; */
}

.row.sad {
  display: flex;
  align-items: center;
}

button#dri\ dropdown-split-basic {
  padding: 2px 9px 4px 9px;
  margin: 7px 0px 7px 0px;
  height: 28px;
}

.navbar-nav .dropdown-menu {
  position: relative;
}

.serch_area.col-md-6.col-12 {
  margin-top: 17px;
}

.bt_mdl_cnt .modal-content {
  background-color: #d2dce3;
}

button.st_mdl_btn.btn.btn-primary {
  width: 100%;
  height: 80px;
  background-color: #0061ad;
  font-size: 25px;
  font-weight: bold;
  border: transparent;
}

.md_title.modal-title.h4 {
  color: #0061ad;
  margin-left: 13px;
}
.dp_btn-basic {
  padding: 11px 0px 11px 0px;
  /* color: black; */
  background: #2e8bd4;
  border-color: #2e8bd4;
  width: 100%;
}
.navbar-expand-lg .offcanvas .offcanvas-body {
  display: block !important;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
.navbar-expand-lg .navbar-nav {
  flex-direction: column !important;
}

.dp_btn-side {
  padding: 11px 0px 11px 0px;
  /* color: black; */
  background: #2e8bd4;
  border-color: #2e8bd4;
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .bx_img img {
    width: 60px;
    margin: 30px;
  }
  .mg_btm {
    margin: 3px;
}
  .bx {
    border: solid 1px transparent;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 15px 0px;
    height: 135px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .bx_hd h5 {
    margin-top: 10px;
    font-size: 15px;
    margin-bottom: 25px;
  }

  .dp_btn {
    float: left;
    /* width: 175px; */
    padding: 0px 25px 0px 25px;
    margin-top: 10px;
    /* margin-right: 72px; */
    margin-left: 0px;
    font-size: 13px;
    height: 30px;
    background-color: #008fda;
    color: black;
    border: solid 1px transparent;
    font-weight: bold;
  }

  .bg_bann {
    position: relative;
    background-image: url("../../public/images/slicing/Help101-Web.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 48vh;
  }

  p.para {
    color: #1763ad;
    font-size: 13px;
    font-weight: 700;
    text-align: left;
    margin: 5px 0px 20px 0px;
  }

  section.bg_blu {
    background-color: #31b0e3;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 8px 20px 0px;
  }

  button.aplus.dhd.btn.btn-primary {
    margin-right: 60px;
  }

  .mb-3.ft_mb3.input-group {
    margin-top: 15px;
  }

  img.logo_ft {
    margin-top: 10px;
    width: 50%;
  }

  .nav_head {
    color: white;
    font-weight: 400;
    margin-top: 2px;
    margin-bottom: 3px;
    font-size: 16px;
    cursor: pointer;
  }

  .line_t {
    border-right: solid #c1c1c1;
  }

  .srch_inp {
    height: 35px;
    background-color: #b5cbd8;
  }

  .line {
    font-size: 11px;
    /* padding: 21px 5px 12px 2px; */
    margin: none;
    border-bottom: 4px solid;
    text-align: center;
    color: #0066a7;
    font-weight: 500;
  }

  .sec h1 {
    text-align: justify;
    font-size: 16px;
    color: #555555;
  }

  .flxs span {
    color: white;
    background: #165788;
    font-size: 11px;
    border-color: #165788;
  }

  .sec {
    border: 1px solid;
    padding: 10px 10px 10px 10px;
    margin: 5px 0px 15px 0px;
    background: #cff1f9;
    border-color: #cff1f9;
    border-radius: 12px;
    width: 100%;
  }

  .fsf h6 {
    color: white;
    font-size: 18px;
    text-align: left;
    margin-top: 10px;
  }

  .hd-1 h5 {
    font-size: 18px;
    text-align: left;
    color: white;
    margin-top: 15px;
  }

  .top-hd h1 {
    text-align: left;
    border-bottom: 2px solid #1f3849;
    padding: 0px 5px 10px 0px;
    color: black;
    font-size: 27px;
  }

  .socialicon img {
    width: 9%;
    border-radius: 5px;
    margin: 12px 12px 22px;
    float: right;
  }

  .aplus {
    height: 30px;
    /* width: 38px; */
    font-size: 13px;
    margin: 5px 5px 0px 0px;
    padding: 5px;
    background-color: #b5cbd8;
    color: black;
    /* font-weight: bold; */
    border: solid 1px transparent;
  }

  .tab_class {
    width: 100%;
    margin-top: 10px;
  }

  .inner-box2 h6 {
    font-size: 12px;
    text-align: justify;
    margin: 9px 1px 7px 0px;
  }

  .inner-box2 h5 {
    text-align: justify;
  }

  button.btn.btn-secondary.bfor {
    margin: 72px 33px 43px;
    padding: 15px 49px;
    color: white;
    background: #1f1f1f;
    font-weight: 700;
  }

  .mb-3.ft_mb3.input-group {
    margin-top: 30px;
  }

  label.btn.btn-toogle {
    padding: 7px 28px;
    display: grid;
    align-items: center;
  }
  .radio-button-group {
    display: flex;
    justify-content: space-around;
  }
  
  .toggle-btn {
    flex-grow: 1;
    margin: 5px;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .toggle-btn.active {
    background-color: #165788; /* Green background when active */
    color: white;
  }
  
  .toggle-btn:not(.active) {
    background-color: #fff; /* White background when inactive */
    border-color: #165788; /* Green border */
    color: white; /* Green text */
  }
  
  .toggle-btn:hover {
    background-color: #165788;
    color: white;
  }
  
  label.dp_btn-side.mg_btm.toggle-btn.btn.btn-outline-success {
    color: white;
}
  /* .btn-group>.btn-group:not(:first-child),
  .btn-group> :not(.btn-check:first-child)+.btn {
    padding: 0px 37px 0px 40px;
    height: 65px;
    margin: 0px 15px 0px 0px;
  } */

  button.tab {
    opacity: 0.9;
  }

  .top-12 {
    font-size: 16px;
  }

  .top-h h5 {
    font-size: 15px;
  }

  .top-h h4 {
    font-size: 19px;
  }

  .md-4.row {
    padding: 0px 0px 23px 0px;
  }

  .hvbj h1 {
    font-size: 21px;
  }

  .hvbj h3 {
    font-size: 18px;
  }

  .hvbj h5 {
    font-size: 15px;
  }

  .dgg {
    margin-top: 33px;
  }

  .dgg {
    margin-top: 33px;
  }

  .collap {
    padding: 17px 18px;
    border-radius: 0px;
    background: #31b0e3;
    border: #31b0e3;
  }

  .tab_con {
    margin-top: 0px;
  }

  button.collap.btn.btn-primary {
    display: block;
  }

  .call.collapse.show {
    display: flex;
    margin-top: 0px;
    flex-direction: column;
  }

  .collapse:not(.show) {
    display: none;
  }

  .btn-toggle {
    padding: 1px 0px 6px;
  }

  .hd-1 h6 {
    margin: 4px;
  }

  .fsf h5 {
    font-size: 16px;
  }

  button.ft.btn.btn-primary {
    margin: 2px 4px 11px 2px;
    padding: 5px 20px 5px 20px;
  }

  .fsf h4 {
    font-size: 19px;
  }

  .ql_fc li {
    font-size: 15px;
  }

  .ql_fc ul {
    margin: 0px 0px 0px 0px;
  }

  .inner-box1 h2 {
    font-size: 12px;
  }

  .inner-box1 h6 {
    font-size: 11px;
  }

  .inner-box2 h2 {
    text-align: left;
    font-size: 13px;
  }

  .inner-box2 h4 {
    text-align: left;
    font-size: 13px;
  }

  .inner-box2 img {
    width: 13%;
  }
  .top_spc.row {
    display: grid;
}



  /* button#dropdown-basic-button {
  margin: 6px 34px 0px -1px;
} */
  /* button#dropdown-basic {
  margin: 5px 2px 2px -37px;
  padding: 8px 38px 8px 38px;
} */
  .dp_btn-basic {
    width: 100%;
  }

  h5.line img {
    width: 34%;
    margin: 0px 0px 8px 0px;
  }
}

/* 
@media screen and (width: 1050px) {
  .dp_btn {
    float: right;
    /* width: 175px; 
    padding: 10px 22px 8px 25px;
    margin-top: -15px;
    margin-right: 10px;
    height: 55px;
    background-color: #008fda;
    color: black;
    border: solid 1px transparent;
    font-weight: bold;
  }
  .tab_class {
    width: 100%;
    margin-top: 10px;
  }
  .dp_btn-basic {
    width: 100% !important;
  }
} */
/* @media screen and (width: 768px) {
  .dp_btn {
    float: right;
    padding: 10px 25px 10px 25px;
    margin-top: 130px;
    margin-right: 39px;
    height: 55px;
    background-color: #008fda;
    color: black;
    border: solid 1px transparent;
    font-weight: bold;
  }
  .tab_name {
    display: none;
  }
  .tab_class {
    width: 100%;
    margin-top: 10px;
  }
  button.tab {
    font-size: 13px;
  }
  .tab {
    padding: 10px 0px;
  }
  .line {
    font-size: 13px;
  }
  .inner-box1 h2 {
    font-size: 11px;
}
.inner-box1 h6 {
  font-size: 10px;
}
.inner-box2 h2 {
  font-size: 29px;
}
.inner-box2 h4 {
  font-size: 18px;
}
.inner-box2 img {
  width: 14%;
}
.inner-box2 h5 {
  font-size: 14px;
}

.dp_btn-basic {
  width: 100% !important;
}
.drp.dropdown-menu.show {
  /* margin: 0px 0px -76px 47px; 
  padding: 9px 0px 9px 0px;
}
.dre.dropdown {
  margin: 13px 2px 0px 37px;
}
.nav_head {
  color: white;
  font-weight: 400;
  margin: 51px 6px -37px 0px;
  font-size: 16px;
}
.dre.dropdown {
  margin: 53px -41px -79px -67px;
  float: right;
}
h5.line img {
  width: 50%;
  margin: 0px 0px 8px 0px;
}
} */