.dp_btn-side_jb {
    /* padding: 11px 0px 11px 0px; */
    /* color: black; */
    background: #165788;
    border-color: transparent;
    /* width: 100%; */
    float: right;
}
.box_myjob {
    padding: 10px 30px 10px 30px;
    background: white;
    box-shadow: 0px 3px 79px -12px rgba(20, 20, 20, 0.4);
    margin: 31px 0px 0px 0px;
    border-radius: 15px;
    transition: transform 0.5s ease;
    cursor: pointer;
    min-height: 150px;
}
.inner-box1_myjob {
    height: 130px;
    overflow: auto;
}
.premium {
    margin-right: 5px;
    border: solid #dbab34 1px;
}
.premium:hover {
    background: linear-gradient(208deg, #fde66d, #ffc105);
}