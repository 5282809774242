.bg_clr_lbluu {
  background-color: #31b0e3;
  padding: 32px;
  border-radius: 5px;
  box-shadow: 0px 8px 20px 0px;
}
.random_txt {
  background-color: white;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  margin: 20px 0px;
  box-shadow: 0px 0px 8px 0px;
  text-align: justify;
}
.sec h1 {
  text-align: justify;
  color: #555555;
}
.flxs span {
  color: white;
  background: #165788;
  border-color: #165788;
}
.flxs span {
  color: white;
  background: #165788;
  border-color: #165788;
}
.top-hd h1 {
  text-align: left;
  border-bottom: 2px solid #1f3849;
  padding: 0px 5px 19px 0px;
  font-size: 37px;
  color: black;
  font-weight: 700;
}
.sec h1 {
  text-align: justify;
  font-size: 24px;
  color: #555555;
}

.bg_dblu.container-fluid {
  background-color: #01192b;
}
.bnk.row {
  border-bottom: 1px solid #1d9ce1;
}
.top-hd h1 {
  color: #005fad;
}
img.logo_ft {
  width: 37%;
}
.mb-3.ft_mb3.input-group {
  margin-top: 80px;
}
.ft_mb3 {
  border: solid 3px #1a435b;
  border-radius: 23px;
  background: #01192b;
}
input.ft_inp.form-control {
  background-color: transparent;
  border: solid transparent;
  color: white;
  border-radius: 22px;
}
.addon2ft {
  border-radius: 31px;
  background-color: #326482;
  border: solid 1px transparent;
  color: white;
  font-weight: bold;
}
ul.list_ft {
  color: white;
  text-align: justify;
}
ul.list_ft h5 {
  font-size: 20px;
}
ul.list_ft li {
  margin: 0px 0px 15px 0px;
  font-size: 19px;
  font-weight: lighter;
}
.socialicon img {
  width: 3%;
  border-radius: 5px;
  margin: 12px 12px 22px;
  float: right;
}
.ft-hr {
  padding: 0;
}
.ft-hr h4 {
  padding: 34px;
  color: white;
  background: #0e2a47;
}
.inp {
  border-color: #165788;
}

label.btn.btn-toogle:hover {
  color: white;
  background: #165788;
  border-color: #165788;
}

.btn-group > .btn-group:not(:first-child), .btn-group > :not(.btn-check:first-child) + .btn {
  margin-left: calc(var(--bs-border-width) * -1);
  color: white;
  /* background: azure; */
  border-radius: 5px;
  /* padding: 15px 50px 15px 50px; */
  /* height: 60px; */
  /* margin: 0px 15px 0px 0px; */
  /* border-color: white; */
}
.dropdown.btn-group {
  float: inline-start;
  margin: 0px 0px 0px -13px;
}
.btn-toggle {
  padding: 1px 0px 18px;
}
.btn-toggle h5 {
  font-size: 28px;
  text-align: start;
  color: #165788;
  margin-top: -14px;
}
li.unit_flx {
  display: flex;
  justify-content: space-between;
  border: solid 1px #e1d0d0;
  padding: 0px 19px;
  background: #f4fcff;
}
.icon_ara img {
  width: 15px;
}
.btn_area_fav {
  text-align: right;
}
.btn_area_fav button {
  margin: 0px 5px;
}

@media only screen and (max-width: 480px) {
  .btn_area_fav {
    text-align: center;
    width: 100%;
  }
  
  .socialicon img {
    width: 9%;
    border-radius: 5px;
    margin: 12px 12px 22px;
    float: right;
  }
  .flxs span {
    color: white;
    background: #165788;
    font-size: 11px;
    border-color: #165788;
  }
  .sec h1 {
    text-align: justify;
    font-size: 16px;
    color: #555555;
  }
  .bg_clr_lbluu {
    background-color: #31b0e3;
    padding: 32px;
    border-radius: 0px;
    box-shadow: 0px 8px 20px 0px;
  }
  .sec h1 {
    text-align: justify;
    font-size: 16px;
    color: #555555;
  }
  .mb-3.ft_mb3.input-group {
    margin-top: 30px;
  }
  label.btn.btn-toogle {
    padding: 7px 28px;
    display: grid;
    align-items: center;
  }
  .btn-group > .btn-group:not(:first-child), .btn-group > :not(.btn-check:first-child) + .btn {
    padding: 0px 37px 0px 40px;
    height: 65px;
    margin: 0px 15px 0px 0px;
}
  .btn-toggle {
    padding: 1px 0px 6px;
}
.ft-hr h4 {
  padding: 34px;
}
}
