.show_area {
    text-align: left;
}
button.btn.bt {
    font-size: 13px;
    font-weight: 700;
    padding-left: 0px;
}
.inner-box1 h2 {
    font-size: 20px;
}
.inner-box1 {
    height: 175px;
    overflow: auto;
}
/* .inner-box1 ::-webkit-scrollbar {
    width: 10px;
  }  */
  .inner-box1::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  .inner-box1::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border: solid 1px;
    border-radius: 10px;
  }
   
  /* Handle */
  .inner-box1::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .inner-box1::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  .inner-box2 img {
    width: 100%;
}

.dp_items{
  color: #2e8bd4;
  font-weight: bold;
}
.drp_dn.dropdown button {
  color: white;
  font-weight: bold;
  height: 52px;
  margin: 15px 0px;
  background: #2e8bd4;
  border-color: #2e8bd4;
}
button.fav.btn.btn-primary {
  background-color: #0a4773;
  border: transparent;
  word-spacing: 10px;
  width: 125px;
}
button.favi.btn.btn-primary {
  background-color: #0a4773;
  border: transparent;
}
.inner-box2 {
  margin-top: -10px;
}
.phn_num {
  display: flex
;
}
@media only screen and (max-width: 480px) {
  .inner-box2 img {
display: none;

}
  
.inner-box2 {
  margin-top: 10px;
}
}