.bg_blu_bk {
    background-color: #31b0e3;
    padding: 32px;
    border-radius: 5px;
    box-shadow: 0px 8px 20px 0px;
    text-align: initial;

}
.wiz_head {
    text-align: center;
    color: #0061ad;
}
.login_back {
    /* background: radial-gradient(#d8d9e3, transparent); */
    border-radius: 10px;
    background: #b0e5f9;
    color: #0061ad;
}
.btn_area {
    display: flex;
    justify-content: center;
    /* margin: 40px 0px; */
    margin-top: 37px;
}

.whl_login {
    padding: 40px;
}
button.btm_btn {
    /* margin: 72px 20px 43px; */
    padding: 14px 20px;
    color: white;
    background: #1f1f1f;
    font-weight: 700;
    border: transparent;
    border-radius: 5px;
    margin: 4px;
}
h3.top_heading_page {
    color: #004873;
}
.drop_dn {
    width: 100%;
    /* margin-top: 10px; */
    background-color: white !important;
    color: #0063ad !important;
    border: solid 1px #dde2e6;
}
.dp_menu {
    width: 100%;
}
.job_bx {
    border: solid 1px #0063ad;
    padding: 10px;
    margin: 1px 0px;
    border-radius: 5px;
}
.btn_area_sbmt{
        display: flex;
        justify-content: end;
        /* margin: 40px 0px; */
        margin-top: 37px;
       
}