.dggg {
  background-color: #bde4f8;
  border-radius: 10px;
  padding: 30px;
  margin-top: 0px;
}
.hvbj {
  text-align: left;
}
.hvbj h1 {
  color: #005fad;
}
.hvbj h3 {
  color: #005fad;
}
.hvbj h5 {
  color: #606060;
}
.bs.row {
  margin-top: 12px;
}

.box {
  padding: 50px 30px 50px 30px;
  background: white;
  box-shadow: 0px 3px 79px -12px rgba(20, 20, 20, 0.4);
  margin: 31px 0px 0px 0px;
  border-radius: 15px;
  transition: transform 0.5s ease;
  cursor: pointer;
  min-height: 270px;
}
.box:hover {
  background: #1ea9e1;
  color: white;
  transition: transform 0.5s ease;
  transform: scale(1.02);
}
.inner-box1 h2 {
  font-weight: bold;
  font-size: 12px;
  text-align: start;
}
.inner-box1 h6 {
  font-size: 15px;
  text-align: start;
}
.inner-box1 a {
  float: inline-start;
}
.inner-box2 h6 {
  font-size: 13px;
  text-align: end;
}
.inner-box2 h5 {
  font-size: 14px;
  text-align: end;
  color: #1459a3;
  font-weight: bold;
}
button.btn.btn-secondary.bfor {
  margin: 72px 20px 43px;
  padding: 14px 20px;
  color: white;
  background: #1f1f1f;
  font-weight: 700;
}
button.bfor.btn.btn-secondary:hover {
  color: white;
  background: #31b0e3;
  border-color: #31b0e3;
}
button.last-b.btn.btn-primary:hover {
  color: white;
  background: #31b0e3;
}
button.btn.btn-primary.last-b {
  margin-top: 28px;
  padding: 14px 26px;
  font-weight: 600;
  color: white;
  background: #1f1f1f;
  border: #1f1f1f;
}
button.btn.btn-primary.last-b {
  margin-top: 28px;
  padding: 14px 26px;
  font-weight: 600;
  color: white;
  background: #1f1f1f;
  border: #1f1f1f;
}
.mb-3.ft_mb3.input-group {
  margin-top: 80px;
}

.bg_dblu.container-fluid {
  background-color: #01192b;
}
.bnk.row {
  border-bottom: 1px solid #1d9ce1;
}
img.logo_ft {
  width: 55%;
}

.ft_mb3 {
  border: solid 3px #1a435b;
  border-radius: 23px;
  background: #01192b;
}
input.ft_inp.form-control {
  background-color: transparent;
  border: solid transparent;
  color: white;
  border-radius: 22px;
}
.addon2ft {
  border-radius: 31px;
  background-color: #326482;
  border: solid 1px transparent;
  color: white;
  font-weight: bold;
}
ul.list_ft {
  color: white;
  text-align: justify;
  margin-top: 40px;
}
ul.list_ft h5 {
  font-size: 20px;
}
ul.list_ft li {
  margin: 0px 0px 15px 0px;
  font-size: 19px;
  font-weight: lighter;
}
.socialicon img {
  width: 3%;
  border-radius: 5px;
  margin: 12px 12px 12px 0px;
  float: right;
  cursor: pointer;
}
.ft-hr {
  padding: 0;
}
.ft-hr h4 {
  padding: 34px;
  color: white;
  margin-bottom: 0px;
  background: #0e2a47;
}
.drp_dn.dropdown button {
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .socialicon img {
    width: 9%;
    border-radius: 5px;
    margin: 12px 12px 22px;
    float: right;
    cursor: pointer;
  }
  img.logo_ft {
    margin-top: 25px;
    width: 35%;
  }
  .inner-box2 h6 {
    font-size: 13px;
    text-align: justify;
  }
  .inner-box2 h5 {
    text-align: justify;
  }
  button.btn.btn-secondary.bfor {
    margin: 34px 7px 3px;
    padding: 11px 11px;
  }
  .mb-3.ft_mb3.input-group {
    margin-top: 30px;
  }
  .top-12 {
    font-size: 16px;
  }
  .top-h h5 {
    font-size: 15px;
  }
  .top-h h4 {
    font-size: 19px;
  }
  .hvbj h1 {
    font-size: 21px;
  }

  .hvbj h3 {
    font-size: 18px;
  }
  .hvbj h5 {
    font-size: 15px;
  }
  button.btn.btn-primary.last-b {
    margin-top: 33px;
    padding: 12px 19px;
}
.bs.row {
  margin-top: 8px;
}
.box {
  padding: 32px 50px 32px 50px;
  margin: 25px 0px 0px 0px;

}
.ft-hr h4 {
  font-size: 14px;
}

.bs.row {
  margin-top: 8px;
}
.box {
  padding: 34px 34px 34px 34px;
  margin: 25px 0px 0px 0px;
}
.ft-hr h4 {
  font-size: 14px;
}
}
 

